.dashboard__items {
    padding: 5rem 0;
    font-size: 1.6rem;
    background-color: #f2f2f2;
}

.elements__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.element {
    flex-basis: 23.8%;
    background-color: #fff;
    border-radius: .8rem;
    padding: 4rem 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

.element__img {
    height: 6rem;
    width: 6rem;
}

@media screen and (max-width: 980px)  { 
    .element {
        flex-basis: 100%;
    }
}