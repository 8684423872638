.productTitle {
    font-size: 2.2rem;
    margin-bottom: 4rem;
    margin-top: 2rem;
}

.card__container {
    margin: 2rem;
}

.product {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    color: #fff;
    padding: 1rem;
    gap: 2rem;
}

.product img {
    width: 50px;
    height: 50px
}

.product p {
    font-size: 2rem;
}