

.diagonostics {
    padding: 7rem 0;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 1200px)  {
    .diagonostics {
        padding: 2rem 0;
    }
} 