.mission__title {
    margin-top: 4rem;
    font-size: 50px;
    margin-left: 2.2rem;
    color: var(--primary-color)
}

.card_container {
    padding: 2rem;
}

.mission {
    display: flex;
    align-items: center;
    background-color: #fff;
}