.card {
    background-color: #fff;
    border-radius: 1.2rem;
    padding: 5rem;
    font-size: 1.8rem;
}

.btn__container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

@media screen and (max-width: 650px)  {
    .scard{
     width: 500px
    }    
}