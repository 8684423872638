.dashboardForm {
    background-color: #f2f2f2;
   padding: 3rem;
   border-radius: 8px;
   width: 70%;
   margin: 0 auto;
}

.dashboardTitle {
    font-size: 22px;
    line-height: 26px;
    color: #434343;
    padding: 2rem 0rem;
}

.form {
    text-align: center;
}

.input {
   width: 100%;
}

.input input{
   width: 100%;
   padding: 15px;
   border: 2px solid gray;
   border-radius: 8px;
   font-size: 22px;
   margin: 15px 0px;
}

textarea {
   width: 100%;
   padding: 25px;
   border: 2px solid gray;
   border-radius: 5px;
   font-size: 22px;
   margin: 15px 0px;
   color: gray;
   min-height: 20rem;
}

.contact_form {
   margin-bottom: 1rem;
}

.contact_form .filter {
    width: 100%;
}

@media screen and (max-width: 650px)  {
   .input input {
       width: 100%;
       padding: 15px;
       border: 2px solid gray;
       border-radius: 8px;
       font-size: 16px;
       margin: 1px 0px;
   }
}