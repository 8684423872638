@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Press+Start+2P&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

:root {
    --primary-color: #111B56;
    --white-color: #fff;
    --dark-grey: #434343;
    --light-sky: #D4F0FC
}

img {
    max-width: 100%;
    max-height: 100%;
}

/* custom class */
.margin_bottom {
    margin-bottom: 2rem;
}
.margin_bottom_medium {
    margin-bottom: 4rem;
}
.margin_bottom_large {
    margin-bottom: 8rem;
}

.margin_bottom_largeX {
    margin-bottom: 1.2rem;
}

.mt_2 {
    margin-top: 2rem;
}

.border__bottom {
    border-bottom: 1px solid #ddd;
}

.container {
    width: 80%;
    margin: 0 auto;
    padding: 2rem 0;
}

.content__container {
    max-width: 110rem;
    width: 80%;
    margin: 0 auto;
}

.text_center {
    text-align: center;
}

.text_xl {
    font-size: 2rem;
}

/*default section padding */
.pd_4 {
    padding: 4rem;
}
.section-pd {
    padding: 5rem 0rem;
}

.flex {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.flex__cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justify_between {
    justify-content: space-between;
}

.justify_center {
    justify-content: center;
}

.flex_wrap {
    flex-wrap: wrap;
}

.grid__container {
    display: grid;
    grid-gap: 4rem;
}

.grid__coloumn_3 {
    grid-template-columns: repeat(4 , 1fr);
}

.grid__coloumn_4 {
    grid-template-columns: repeat(3 , 1fr);
}

.grid__coloumn_6 {
    grid-template-columns: repeat(2, 1fr);
}

.grid__coloumn_2Something {
    grid-template-columns: 200px 110px 170px 170px 1fr;
    /* grid-template-columns: repeat(5, 1fr); */
}

.btn__blue {
    background: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 1.5rem 4rem;
    border-radius: .8rem;
}

.mb_2 {
    margin-bottom: 2rem;
}

.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.active {
    color: red !important;
    text-decoration:  none;
    font-weight: 700;
}


.success {
    font-size: 2rem;
    color: var(--primary-color);
    font-weight: 700;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}

.vaccine_card {
    background-color: rgba(221, 221, 221, 0.37);
    margin-bottom: 2rem;
    border-radius: .8rem;
}

.vaccine_card >div {
    padding: 5rem;
}

.vaccine_left_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vaccine_card h2 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    z-index: 2;
}

.vaccine_card p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

.vaccine_card .vaccine_left {
    padding: 5rem;
    background-image: url("../src/assets/images/covid1.png");
    background-size: contain;
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: 100% 0%;
}

.vaccine_left div {
    display: flex;
    justify-content: space-between;
}

.vaccine_left div p:last-child {
    font-weight: 700;
}

.vaccine_card button {
    margin-top: 2rem;
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.8rem;
    padding: 1rem 2rem;
    border-radius: .4rem;
}

.update_progress {
    padding: 1rem 4rem;
    background-color: rgba(65, 105, 225, 0.486);
    display: inline-block;
    color: #fff;
    margin-bottom: 3rem;
    font-size: 1.6rem;
}

.update_success {
    padding: 1rem 4rem;
    background-color: royalblue;
    display: inline-block;
    color: #fff;
    margin-bottom: 3rem;
    font-size: 1.6rem;
}

.tableScrool {
    width: 100%;
    height: 500px;
    overflow-x: scroll;
    border-radius: .8rem;
}


.scrollBar {
    overflow-y: auto;
}

.scrollBar::-webkit-scrollbar {
    width: 5px;
  }
  
.scrollBar::-webkit-scrollbar-track {
background-color: #e4e4e4;
border-radius: 100px;
}
  
.scrollBar::-webkit-scrollbar-thumb {
border-radius: 100px;
border: 5px solid #008080;
border-left: 0;
border-right: 0;
background-color: #008080;
}

.loadingImg {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Pagination Styling start */
.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    color:  var(--primary-color);
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color:  var(--primary-color);
  }
  
  .paginationActive a {
    color: white;
    background-color:  var(--primary-color);
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: grey;
  }
  /* Pagination Styling end */
  

/* dashboard adding form */
.dashboardForm {
    background-color: #f2f2f2;
   padding: 3rem;
   border-radius: 8px;
   width: 70%;
   margin: 0 auto;
}

.dashboardTitle {
    font-size: 22px;
    line-height: 26px;
    color: #434343;
    padding: 2rem 0rem;
}


/* dropdown */
.dropbtn {
    background-color: #fff;
    color: #000;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .dropdown {
    position: relative;
    display: block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    width: 100%;
    margin-top: .2rem;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;

  }
  
  .dropdown:hover .dropbtn {
    background-color: #fff;
    color: #000
  }

.form {
    text-align: center;
}

.input {
   width: 100%;
}

.input input{
   width: 100%;
   padding: 15px;
   border: 2px solid gray;
   border-radius: 8px;
   font-size: 22px;
   margin: 15px 0px;
}

textarea {
   width: 100%;
   padding: 25px;
   border: 2px solid gray;
   border-radius: 5px;
   font-size: 22px;
   margin: 15px 0px;
   color: gray;
   min-height: 20rem;
}

.contact_form {
   margin-bottom: 1rem;
}

/* Nothing Page */
.nothing__page {
    min-height: 82vh;
    color: var(--primary-color);
    font-size: 2.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

/* Dashboard table */

table {
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;

    width:100%;
    max-width: 100%;
}

.table__heading th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
}

.table__heading {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
}

.table__body {
    background-color: #fff;
    border-radius: .8rem;
}

.table__body tr td {
    padding: 1rem 2rem;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}


/* filter button css */
.filter {
    margin-bottom: 5rem;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
}

.filter__label {
    font-size: 1.8rem;
    display: block;
    text-transform: uppercase;
    margin-bottom: .5rem;
    font-weight: 600;
    color: var(--primary-color);
}

.selected {
    padding: .5rem 1rem;
    font-size: 2rem;
    display: block;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: .4rem;
    width: 100%;
}

.select_input {
    font-size: 2rem;
    display: block;
    color: #000;
    width: 100%;
    
    padding: 1.5rem;
    border: 2px solid #ddd;
    border-radius: .4px;
    font-size: 16px;
    margin: 1px 0px;
}



/* dashboard table */

table {
    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;

  width:100%;
  max-width: 100%;
}

.table__heading th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
    
}

.table__heading {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
}

.table__body {
    background-color: #fff;
    border-radius: .8rem;
}



table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}

.pagination_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_container ul{
    display: flex;
    flex-wrap: wrap;
}

.pagination_container ul li {
    margin: 3rem 0
}

@media screen and (max-width: 1700px)  {
    .tableScrool {
        width: 1000px !important;
        overflow: scroll;
    }
}

@media screen and (max-width: 1500px)  {
    .tableScrool {
        width: 800px !important;
        overflow: scroll;
    }
}


@media screen and (max-width: 1430px)  {
    .grid__container {
        gap: 1rem !important
    }
    .grid__coloumn_2Something {
        grid-template-columns: 1fr 100px repeat(3, 1fr);
    }
}

@media screen and (max-width: 1200px)  {
    .grid__coloumn_2Something {
        grid-template-columns: 1fr 100px repeat(3, 180px);
    }
    .grid__coloumn_4 {
        grid-template-columns: 1fr;
    }
    .tableScrool {
        width: 600px !important;
        overflow: scroll;
    }
}


@media screen and (max-width: 1080px)  {
    .grid__coloumn_3 {
        grid-template-columns: 1fr 1fr;
    }


    .grid__coloumn_2Something {
        grid-template-columns: repeat(3, 1fr);
    }
    .grid__container{
        grid-gap: 2rem !important;
    }
}

@media screen and (max-width: 1050px)  {
    html {
        font-size: 56.5%;
    }

    .grid__container{
        grid-gap: 2rem !important;
    }
}

@media screen and (max-width: 880px)  {
    .grid__coloumn_2Something {
        grid-template-columns: repeat(2, 1fr);
    } 
    .grid__coloumn_6 {
        grid-template-columns: 1fr;
    }

    .flex__cart {
        display: block;
    }
}

@media screen and (max-width: 720px)  {
    .pagination_container {
        padding: 0rem 0 8rem 0;
    }
}
        
@media screen and (max-width: 650px)  {
    .text__center_sm {
        text-align: center;
    }
    .grid__container{
        grid-gap: 2rem !important;
    }


    .grid__coloumn_3 {
        grid-template-columns: 1fr;
    }

    .grid__coloumn_2Something {
        grid-template-columns: 1fr;
    } 
    
    .flex {
        justify-content: center;
    }

    .section-pd {
        padding: 2rem 1rem;
    }

    .input input {
        width: 100%;
        padding: 15px;
        border: 2px solid gray;
        border-radius: 8px;
        font-size: 16px;
        margin: 1px 0px;
    }

    .filter {
        margin-bottom: 2rem;
    }

    .diagnostics_table {
        overflow-x:auto;
    }

    .vaccine_card >div {
        padding: 4rem 2rem 2rem;
    }

    .vaccine_card .vaccine_left {
        padding: 2rem 2rem 4rem;
    }
 
}

@media screen and (max-width: 720px)  {
    .pagination_container {
        padding: 0rem 0 15rem 0;
    }
}