.treatment__page {
    min-height: 75vh;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

@media screen and (max-width: 950px)  {
    .treatment__page {
        flex-direction: column;
    }
}