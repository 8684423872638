.contact_headline {
    padding: 3rem 0rem;
    max-width: 60%;
    margin: 0 auto;
}

.contact_headline span {
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 5rem;
}

.contact_headline h2 {
    font-weight: bold;
    font-size: 4rem;
    line-height: 7rem;
}

.contact_headline p {
    font-size: 2.2rem;
}

@media screen and (max-width: 950px)  {
    .contact_headline{
        max-width: 70%;
        /* padding: 1rem 0 3rem 0; */
    }

    .contact_headline h2 {
        font-size: 3.2rem;
    }
}

@media screen and (max-width: 650px)  {
    .contact_headline{
        max-width: 80%;
        padding: 1rem 0 3rem 0;
    }

    .contact_headline span {
        font-size: 1.8rem;
    }

    .contact_headline h2 {
        font-size: 2.2rem;
    }

    .contact_headline p {
        font-size: 1.6rem;
    }
}