.filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hospital_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hospital_details img {
    width: 60rem;
    height: 40rem;
    border-radius: .8rem;
    margin: 3rem 0rem;
}

.hospital_details h6 {
    font-size: 4.4rem;
    margin-bottom: 2rem;
}
.hospital_details p {
    font-size: 1.8rem;
}


@media screen and (max-width: 700px)  {
    .filterContainer {
        display: block;
    }
}