.social_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.socal__icons img {
    margin-top: 4rem;
    width: 50px;
    height: 50px;
}
.facebook {
    margin-top: 4rem;
    width: 30px;
    height: 30px;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    font-size: 1.8rem;
    margin-top: 2rem;
    color: var(--primary-color);
    font-weight: 700;
}

.details div {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.details div p {
    font-weight: 400;
}

.details div a {
    font-weight: 400;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

@media screen and (max-width: 980px)  {
    .social_container {
        align-items: center;
    }
}