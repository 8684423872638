.footer {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 8rem 0;
}

.footer_grid {
    gap: 4rem
}

.footer__logo {
    margin-bottom: 2rem;
    min-width: 200px;
}

.footer__location_details {
    color: #fff;
    font-weight: 300;
    font-style: normal;
    font-size: 1.8rem;
}

.footer_lists {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem
}
.footer_list {
    font-size: 1.8rem;
    font-weight: 300;
}
.footer_list:first-child {
    font-size: 1.8rem;
    font-weight: 700;
}

.partner {
    border: 2px solid #fff;
    padding: 1rem;
    /* overflow: hidden; */
    display: inline-block;
}

.footer__greet {
    font-size: 1.8rem;
    width: 100%;
    margin-top: 2rem;
}

.Details {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.footer__social {
    margin: 0 auto ;
}

@media screen and (max-width: 650px)  {
    .footer__greet {
        padding-top: 2rem;
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
}