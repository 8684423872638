
.top__navigation_container {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
    min-height: 8vh;

    display: flex;
    align-items: center;
}

.top__navigation {
    width: 80%;
    margin: 0 auto;
    padding: 1rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem
}

.top__navigation_logo {
    width: 300px;
    min-width: 20%;
    height: 100%;
}


.form__button {
    background-color: rgb(230, 0, 0);
    color: #fff;
    border: none;
    padding: 1rem 3.5rem ;
    font-size: 1.8rem;
    border-radius: .8rem;
    text-transform: uppercase;
    cursor: pointer;
}

.list__container {
    display: flex;
    justify-content: flex-end;
}

.list {
    margin: 2rem;
}

.list__container .list a {
    text-decoration: none;
    font-size: 2.2rem;
    color: var(--dark-grey);
}



@media screen and (max-width: 1200px)  {
    .form__button { 
        right: 15%;
    }
}


@media screen and (max-width: 540px)  {
    .top__navigation {
        flex-direction: column;
    }
}