.sidebar {
    flex-basis: 15%;
    background-color: royalblue;
    color:black;
}

.sidebar__container {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6rem;
    gap: 2rem;
    padding: 2rem 5rem;    
}

.sidebar__item {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
}

