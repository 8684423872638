.body__parts {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
}


.adult__body, .women__body, .baby__body {
    flex: 1;
    cursor: pointer;
    fill: #fff;
    stroke: #000;
}


.adult__body:hover, .women__body:hover, .baby__body:hover {
    fill: #008080;
    stroke: #000;
}

.vaccine__title {
    text-align: center;
    margin-bottom: 3rem;
}

.vaccine__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vaccineName {
    background: #00acac;
    width: 90%;
    padding: 2rem 0;
    text-align: center;
    margin: 1rem;
    color: #fff;
    border-radius: .8rem;
}


.bglight {
    background-color: #008080 !important;
}

.fillight {
    fill: #00acac ;
}

.hover:.hover {
    fill: red !important;
}

@media screen and (max-width: 650px)  {
    .menRegion {
        height: 190px;
        width: 90px;
        margin-top: 50px;
    }
    
    .womenRegion {
        height: 180px;
        width: 80px;
    }

    .babyRegion {
        height: 150px;
        width: 80px;
    }
}

@media screen and (max-width: 350px)  {
    .menRegion {
        height: 170px;
        width: 75px;
        margin-top: 50px;
    }
    
    .womenRegion {
        height: 160px;
        width: 65px;
    }

    .babyRegion {
        height: 130px;
        width: 55px;
    }
}