.formlist__container {
    display: flex;
    gap: 2rem;
    min-height: 82vh;
}

.formlist__form {
    flex-basis: 85%;
}
.formlist__listTable {
    /* background-color: #F2F2F2; */
    padding: 3rem;
    margin: 3rem;
    /* border-radius: .8rem; */
}