.treatments {
    display: flex;
    gap: 2rem;
    min-height: 82vh;
}

.treatments__form {
    flex-basis: 85%;
}

.treatments__table {
    background-color: #F2F2F2;
    padding: 3rem;
    margin: 3rem;
    border-radius: .8rem;
}