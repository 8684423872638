
table {
    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;

  width:100%;
  max-width: 100%;
  position: relative;
}

.table__heading th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
    
}

.table__heading {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
    position: sticky;
    top: 0;
    left: 0;
}

.table__body {
    background-color: #fff;
    border-radius: .8rem;
}



table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}




.label {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.input {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.btn {
    width: 100%;
    border: none;
    padding: 2rem 3rem;
    border-radius: .4rem;
}