.details {
    width: 100%;
    color: #000;
    background-color: var(--white-color);
    border-radius: 1rem;
    padding: 8rem 4rem;
    margin: 3rem;
}

.details__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.body__segment_container {
    flex-basis: 33.33%;
}



.body__segment {
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 300px;
    border-right: 5px solid #008080;
    /* margin: 0 -10px !important; */
}

.body__segment_baby {
    margin-right: 20px;
}

.details__subtitle {
    text-align: center;
    margin-bottom: 3rem;
}

.organ__selection {
    flex-basis: 33.33%;
    text-align: center;
}


.organ__lists {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}
.organ__list {
    flex-basis: 33%;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

/* .organ__list img {
    width: 12rem;
    height: 10rem;
} */

.organImg {
    height: 8rem;
    margin: 1rem;
    padding: 10px;
}

.organImg:hover {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}



.bg {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}


.symptom {
    flex-basis: 33.33%;
}
.symptom__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.symptom__name {
    background: #00b6b6;
    width: 90%;
    padding: 2rem 1rem;
    text-align: center;
    margin: 1rem;
    color: #fff;
    border-radius: .8rem;
}

.body__segment, .organ__lists, .symptom__list {
    margin: 2rem 0rem !important;
    padding: 1rem;
    /* border-radius: .8rem; */
    height: 300px;
}

.body__part {
    cursor: pointer;
}

.body__part:hover {
    fill: #008080;
}



.btn__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    border-radius: .8rem;
    margin-top: 2rem;
    cursor: pointer;
}

.bglight {
    background-color: #008080 !important;
}
@media screen and (max-width: 1080px)  {
    .body__segment {
        justify-content: space-around;
    }
}
@media screen and (max-width: 980px)  {
    .details {
        width: 90%;
    }

    .details__container { 
        display: block;
    }
    .body__segment_container,.organ__lists, .organ__selection, .symptom__list {
        flex-basis: 100%;
        min-height: auto;
    }

    .body__segment, .organ__lists, .symptom_name {
        border: 0px;
    }
}

@media screen and (max-width: 650px)  {
    .details {
        padding: 4rem 2rem;
        margin: 0 3rem;
    }
    .menRegion {
        height: 190px;
        width: 90px;
        margin-top: 50px;
    }
    
    .womenRegion {
        height: 180px;
        width: 80px;
    }

    .babyRegion {
        height: 150px;
        width: 80px;
    }
}

@media screen and (max-width: 350px)  {
    .menRegion {
        height: 170px;
        width: 75px;
        margin-top: 50px;
    }
    
    .womenRegion {
        height: 160px;
        width: 65px;
    }

    .babyRegion {
        height: 130px;
        width: 55px;
    }

    .body__segment { 
        height: auto;
    }
}




