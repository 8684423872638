.about_partnership {
    margin-left: 3rem;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.about_partnership span {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #111B56;
}

.about_partnership h3 {
    font-weight: bold;
    font-size: 50px;
    line-height: 68px;
    color: #111B56;
}

.about_partnership p {
    font-weight: normal;
    font-size: 22px;
    line-height: 35px;
    color: #262626;
}