.hospital__card {
    background-color: var(--light-sky);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .8rem;
}

.hospital__img {
    border-radius: .8rem .8rem 0 0;
}

.hospital__name {
    font-size: 2.2rem;
    margin: 2rem 0 0;
}

.hospital__tags {
    margin: 4rem 0;
    display: flex;
    row-gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
}

.tag {
    background-color: #fff;
    padding: .6rem 1rem;
    border-radius: .8rem;
    font-size: 1.6rem;
    margin: 0 1rem 0 0;
}