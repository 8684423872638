.diagonostics__card {
    /* border: 5px solid #ddd; */
    padding: 2rem;
    background-color: rgba(221, 221, 221, 0.37);
    border-radius: .8rem;
}

.diagonostics__img_container {
    display: flex;
    gap: 1rem;
}


.diagonostics__img {
    width: 50%;
}

.diagonostics__name {
    font-size: 1.8rem;
    margin-top: 2rem;
}

.diagonostics__price {
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.price {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 2rem;    
}

.diagonostics__btn {
    border: none;
    background: none;
    padding: 1rem 2rem;
    background-color: var(--primary-color);
    color: #fff;
    margin-top: 3rem;
    cursor: pointer;
}

.diagonostics__btn:hover {
    background-color: rgb(113, 113, 255);
}