@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Press+Start+2P&display=swap);
.Home_homepage__djYX7 {
    min-height: 75vh;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

@media screen and (max-width: 950px)  {
    .Home_homepage__djYX7 {
        flex-direction: column;
    }
}
.Details_details__39uMu {
    width: 100%;
    color: #000;
    background-color: var(--white-color);
    border-radius: 1rem;
    padding: 8rem 4rem;
    margin: 3rem;
}

.Details_details__container__2d3W0 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.Details_body__segment_container__1Tt3n {
    flex-basis: 33.33%;
}



.Details_body__segment__nwsGU {
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 300px;
    border-right: 5px solid #008080;
    /* margin: 0 -10px !important; */
}

.Details_body__segment_baby__1iDPv {
    margin-right: 20px;
}

.Details_details__subtitle__3w50P {
    text-align: center;
    margin-bottom: 3rem;
}

.Details_organ__selection__1N3G4 {
    flex-basis: 33.33%;
    text-align: center;
}


.Details_organ__lists__11h_G {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}
.Details_organ__list__CF92M {
    flex-basis: 33%;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

/* .organ__list img {
    width: 12rem;
    height: 10rem;
} */

.Details_organImg__2N2t8 {
    height: 8rem;
    margin: 1rem;
    padding: 10px;
}

.Details_organImg__2N2t8:hover {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}



.Details_bg__2voce {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}


.Details_symptom__2cgP0 {
    flex-basis: 33.33%;
}
.Details_symptom__list__1fH2w {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Details_symptom__name__1lqYq {
    background: #00b6b6;
    width: 90%;
    padding: 2rem 1rem;
    text-align: center;
    margin: 1rem;
    color: #fff;
    border-radius: .8rem;
}

.Details_body__segment__nwsGU, .Details_organ__lists__11h_G, .Details_symptom__list__1fH2w {
    margin: 2rem 0rem !important;
    padding: 1rem;
    /* border-radius: .8rem; */
    height: 300px;
}

.Details_body__part__84FNi {
    cursor: pointer;
}

.Details_body__part__84FNi:hover {
    fill: #008080;
}



.Details_btn__container__1F461 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Details_btn__mVIN7 {
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    border-radius: .8rem;
    margin-top: 2rem;
    cursor: pointer;
}

.Details_bglight__3vPbO {
    background-color: #008080 !important;
}
@media screen and (max-width: 1080px)  {
    .Details_body__segment__nwsGU {
        justify-content: space-around;
    }
}
@media screen and (max-width: 980px)  {
    .Details_details__39uMu {
        width: 90%;
    }

    .Details_details__container__2d3W0 { 
        display: block;
    }
    .Details_body__segment_container__1Tt3n,.Details_organ__lists__11h_G, .Details_organ__selection__1N3G4, .Details_symptom__list__1fH2w {
        flex-basis: 100%;
        min-height: auto;
    }

    .Details_body__segment__nwsGU, .Details_organ__lists__11h_G, .Details_symptom_name__18zMj {
        border: 0px;
    }
}

@media screen and (max-width: 650px)  {
    .Details_details__39uMu {
        padding: 4rem 2rem;
        margin: 0 3rem;
    }
    .Details_menRegion__J1Qmx {
        height: 190px;
        width: 90px;
        margin-top: 50px;
    }
    
    .Details_womenRegion__JzZXO {
        height: 180px;
        width: 80px;
    }

    .Details_babyRegion__1ZlMw {
        height: 150px;
        width: 80px;
    }
}

@media screen and (max-width: 350px)  {
    .Details_menRegion__J1Qmx {
        height: 170px;
        width: 75px;
        margin-top: 50px;
    }
    
    .Details_womenRegion__JzZXO {
        height: 160px;
        width: 65px;
    }

    .Details_babyRegion__1ZlMw {
        height: 130px;
        width: 55px;
    }

    .Details_body__segment__nwsGU { 
        height: auto;
    }
}






.AboutHealthPort_about_healthport__3wG5R {
    margin-right: 3rem;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.AboutHealthPort_about_healthport__3wG5R span {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #111B56;
}

.AboutHealthPort_about_healthport__3wG5R h3 {
    font-weight: bold;
    font-size: 50px;
    line-height: 68px;
    color: #111B56;
}

.AboutHealthPort_about_healthport__3wG5R p {
    font-weight: normal;
    font-size: 22px;
    line-height: 35px;
    color: #262626;
}
.PartnerShip_about_partnership__s33TX {
    margin-left: 3rem;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.PartnerShip_about_partnership__s33TX span {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #111B56;
}

.PartnerShip_about_partnership__s33TX h3 {
    font-weight: bold;
    font-size: 50px;
    line-height: 68px;
    color: #111B56;
}

.PartnerShip_about_partnership__s33TX p {
    font-weight: normal;
    font-size: 22px;
    line-height: 35px;
    color: #262626;
}
.AboutMission_mission__title__11DUc {
    margin-top: 4rem;
    font-size: 50px;
    margin-left: 2.2rem;
    color: var(--primary-color)
}

.AboutMission_card_container__3vJO3 {
    padding: 2rem;
}

.AboutMission_mission__K6yn6 {
    display: flex;
    align-items: center;
    background-color: #fff;
}
.ContactTitle_contact_headline__3kHu1 {
    padding: 3rem 0rem;
    max-width: 60%;
    margin: 0 auto;
}

.ContactTitle_contact_headline__3kHu1 span {
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 5rem;
}

.ContactTitle_contact_headline__3kHu1 h2 {
    font-weight: bold;
    font-size: 4rem;
    line-height: 7rem;
}

.ContactTitle_contact_headline__3kHu1 p {
    font-size: 2.2rem;
}

@media screen and (max-width: 950px)  {
    .ContactTitle_contact_headline__3kHu1{
        max-width: 70%;
        /* padding: 1rem 0 3rem 0; */
    }

    .ContactTitle_contact_headline__3kHu1 h2 {
        font-size: 3.2rem;
    }
}

@media screen and (max-width: 650px)  {
    .ContactTitle_contact_headline__3kHu1{
        max-width: 80%;
        padding: 1rem 0 3rem 0;
    }

    .ContactTitle_contact_headline__3kHu1 span {
        font-size: 1.8rem;
    }

    .ContactTitle_contact_headline__3kHu1 h2 {
        font-size: 2.2rem;
    }

    .ContactTitle_contact_headline__3kHu1 p {
        font-size: 1.6rem;
    }
}
.Button_btn__1Jn-K {
    background: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 1.5rem 4rem;
    border-radius: .8rem;
}
.ContactForm_form__3hpdW {
     max-width: 80%;
    margin: 0 auto;
    text-align: center;
}

.ContactForm_input__HlZiB {
    width: 100%;
}

.ContactForm_input__HlZiB input{
    width: 100%;
    padding: 15px;
    border: 2px solid gray;
    border-radius: 8px;
    font-size: 22px;
    margin: 15px 0px;
}

textarea {
    width: 100%;
    padding: 25px;
    border: 2px solid gray;
    border-radius: 5px;
    font-size: 22px;
    margin: 15px 0px;
    color: gray;
    min-height: 20rem;
}

.ContactForm_contact_form__2c7uj {
    margin-bottom: 1rem;
}

@media screen and (max-width: 650px)  {
    .ContactForm_input__HlZiB input {
        width: 100%;
        padding: 15px;
        border: 2px solid gray;
        border-radius: 8px;
        font-size: 16px;
        margin: 1px 0px;
    }
    .ContactForm_form__3hpdW {
        max-width: 100%;
   }
   
}
.ContactSocial_social_container__20aCc {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ContactSocial_socal__icons__WT822 img {
    margin-top: 4rem;
    width: 50px;
    height: 50px;
}
.ContactSocial_facebook__20co3 {
    margin-top: 4rem;
    width: 30px;
    height: 30px;
}

.ContactSocial_details__2O-ec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    font-size: 1.8rem;
    margin-top: 2rem;
    color: var(--primary-color);
    font-weight: 700;
}

.ContactSocial_details__2O-ec div {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ContactSocial_details__2O-ec div p {
    font-weight: 400;
}

.ContactSocial_details__2O-ec div a {
    font-weight: 400;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

@media screen and (max-width: 980px)  {
    .ContactSocial_social_container__20aCc {
        align-items: center;
    }
}
.Contact_contact__2kET9 {
    max-width: 60%;
    margin: 0 auto;
}

.Contact_grid__13MLk {
  display: grid;
  grid-gap: 4rem;
  gap: 4rem;
  grid-template-columns: minmax(35rem, 1fr) minmax(35rem, 1fr);
  align-items: center;
}


@media screen and (max-width: 980px)  {
  .Contact_grid__13MLk {
    grid-template-columns: 1fr;
  }   
}

@media screen and (max-width: 650px)  {
  .Contact_contact__2kET9 {
      max-width: 90%;
  }
   
}
.Hospital_hospital__card__3KXRW {
    background-color: var(--light-sky);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .8rem;
}

.Hospital_hospital__img__1zkS_ {
    border-radius: .8rem .8rem 0 0;
}

.Hospital_hospital__name__1Su1N {
    font-size: 2.2rem;
    margin: 2rem 0 0;
}

.Hospital_hospital__tags__Tp_Gj {
    margin: 4rem 0;
    display: flex;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
}

.Hospital_tag__icd4H {
    background-color: #fff;
    padding: .6rem 1rem;
    border-radius: .8rem;
    font-size: 1.6rem;
    margin: 0 1rem 0 0;
}
.Hospitals_filterContainer__-K1po {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Hospitals_hospital_details__1otwd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Hospitals_hospital_details__1otwd img {
    width: 60rem;
    height: 40rem;
    border-radius: .8rem;
    margin: 3rem 0rem;
}

.Hospitals_hospital_details__1otwd h6 {
    font-size: 4.4rem;
    margin-bottom: 2rem;
}
.Hospitals_hospital_details__1otwd p {
    font-size: 1.8rem;
}


@media screen and (max-width: 700px)  {
    .Hospitals_filterContainer__-K1po {
        display: block;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

:root {
    --primary-color: #111B56;
    --white-color: #fff;
    --dark-grey: #434343;
    --light-sky: #D4F0FC
}

img {
    max-width: 100%;
    max-height: 100%;
}

/* custom class */
.margin_bottom {
    margin-bottom: 2rem;
}
.margin_bottom_medium {
    margin-bottom: 4rem;
}
.margin_bottom_large {
    margin-bottom: 8rem;
}

.margin_bottom_largeX {
    margin-bottom: 1.2rem;
}

.mt_2 {
    margin-top: 2rem;
}

.border__bottom {
    border-bottom: 1px solid #ddd;
}

.container {
    width: 80%;
    margin: 0 auto;
    padding: 2rem 0;
}

.content__container {
    max-width: 110rem;
    width: 80%;
    margin: 0 auto;
}

.text_center {
    text-align: center;
}

.text_xl {
    font-size: 2rem;
}

/*default section padding */
.pd_4 {
    padding: 4rem;
}
.section-pd {
    padding: 5rem 0rem;
}

.flex {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.flex__cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justify_between {
    justify-content: space-between;
}

.justify_center {
    justify-content: center;
}

.flex_wrap {
    flex-wrap: wrap;
}

.grid__container {
    display: grid;
    grid-gap: 4rem;
}

.grid__coloumn_3 {
    grid-template-columns: repeat(4 , 1fr);
}

.grid__coloumn_4 {
    grid-template-columns: repeat(3 , 1fr);
}

.grid__coloumn_6 {
    grid-template-columns: repeat(2, 1fr);
}

.grid__coloumn_2Something {
    grid-template-columns: 200px 110px 170px 170px 1fr;
    /* grid-template-columns: repeat(5, 1fr); */
}

.btn__blue {
    background: #111B56;
    background: var(--primary-color);
    color: #fff;
    color: var(--white-color);
    border: none;
    padding: 1.5rem 4rem;
    border-radius: .8rem;
}

.mb_2 {
    margin-bottom: 2rem;
}

.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.active {
    color: red !important;
    text-decoration:  none;
    font-weight: 700;
}


.success {
    font-size: 2rem;
    color: #111B56;
    color: var(--primary-color);
    font-weight: 700;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}

.vaccine_card {
    background-color: rgba(221, 221, 221, 0.37);
    margin-bottom: 2rem;
    border-radius: .8rem;
}

.vaccine_card >div {
    padding: 5rem;
}

.vaccine_left_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vaccine_card h2 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    z-index: 2;
}

.vaccine_card p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

.vaccine_card .vaccine_left {
    padding: 5rem;
    background-image: url(/static/media/covid1.8bda768a.png);
    background-size: contain;
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: 100% 0%;
}

.vaccine_left div {
    display: flex;
    justify-content: space-between;
}

.vaccine_left div p:last-child {
    font-weight: 700;
}

.vaccine_card button {
    margin-top: 2rem;
    border: none;
    background-color: #111B56;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.8rem;
    padding: 1rem 2rem;
    border-radius: .4rem;
}

.update_progress {
    padding: 1rem 4rem;
    background-color: rgba(65, 105, 225, 0.486);
    display: inline-block;
    color: #fff;
    margin-bottom: 3rem;
    font-size: 1.6rem;
}

.update_success {
    padding: 1rem 4rem;
    background-color: royalblue;
    display: inline-block;
    color: #fff;
    margin-bottom: 3rem;
    font-size: 1.6rem;
}

.tableScrool {
    width: 100%;
    height: 500px;
    overflow-x: scroll;
    border-radius: .8rem;
}


.scrollBar {
    overflow-y: auto;
}

.scrollBar::-webkit-scrollbar {
    width: 5px;
  }
  
.scrollBar::-webkit-scrollbar-track {
background-color: #e4e4e4;
border-radius: 100px;
}
  
.scrollBar::-webkit-scrollbar-thumb {
border-radius: 100px;
border: 5px solid #008080;
border-left: 0;
border-right: 0;
background-color: #008080;
}

.loadingImg {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Pagination Styling start */
.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #111B56;
    border: 1px solid var(--primary-color);
    color:  #111B56;
    color:  var(--primary-color);
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color:  #111B56;
    background-color:  var(--primary-color);
  }
  
  .paginationActive a {
    color: white;
    background-color:  #111B56;
    background-color:  var(--primary-color);
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: grey;
  }
  /* Pagination Styling end */
  

/* dashboard adding form */
.dashboardForm {
    background-color: #f2f2f2;
   padding: 3rem;
   border-radius: 8px;
   width: 70%;
   margin: 0 auto;
}

.dashboardTitle {
    font-size: 22px;
    line-height: 26px;
    color: #434343;
    padding: 2rem 0rem;
}


/* dropdown */
.dropbtn {
    background-color: #fff;
    color: #000;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .dropdown {
    position: relative;
    display: block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    width: 100%;
    margin-top: .2rem;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;

  }
  
  .dropdown:hover .dropbtn {
    background-color: #fff;
    color: #000
  }

.form {
    text-align: center;
}

.input {
   width: 100%;
}

.input input{
   width: 100%;
   padding: 15px;
   border: 2px solid gray;
   border-radius: 8px;
   font-size: 22px;
   margin: 15px 0px;
}

textarea {
   width: 100%;
   padding: 25px;
   border: 2px solid gray;
   border-radius: 5px;
   font-size: 22px;
   margin: 15px 0px;
   color: gray;
   min-height: 20rem;
}

.contact_form {
   margin-bottom: 1rem;
}

/* Nothing Page */
.nothing__page {
    min-height: 82vh;
    color: #111B56;
    color: var(--primary-color);
    font-size: 2.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

/* Dashboard table */

table {
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;

    width:100%;
    max-width: 100%;
}

.table__heading th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
}

.table__heading {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
}

.table__body {
    background-color: #fff;
    border-radius: .8rem;
}

.table__body tr td {
    padding: 1rem 2rem;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}


/* filter button css */
.filter {
    margin-bottom: 5rem;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
}

.filter__label {
    font-size: 1.8rem;
    display: block;
    text-transform: uppercase;
    margin-bottom: .5rem;
    font-weight: 600;
    color: #111B56;
    color: var(--primary-color);
}

.selected {
    padding: .5rem 1rem;
    font-size: 2rem;
    display: block;
    background-color: #111B56;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: .4rem;
    width: 100%;
}

.select_input {
    font-size: 2rem;
    display: block;
    color: #000;
    width: 100%;
    
    padding: 1.5rem;
    border: 2px solid #ddd;
    border-radius: .4px;
    font-size: 16px;
    margin: 1px 0px;
}



/* dashboard table */

table {
    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;

  width:100%;
  max-width: 100%;
}

.table__heading th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
    
}

.table__heading {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
}

.table__body {
    background-color: #fff;
    border-radius: .8rem;
}



table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.done {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.pending {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}

.pagination_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_container ul{
    display: flex;
    flex-wrap: wrap;
}

.pagination_container ul li {
    margin: 3rem 0
}

@media screen and (max-width: 1700px)  {
    .tableScrool {
        width: 1000px !important;
        overflow: scroll;
    }
}

@media screen and (max-width: 1500px)  {
    .tableScrool {
        width: 800px !important;
        overflow: scroll;
    }
}


@media screen and (max-width: 1430px)  {
    .grid__container {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }
    .grid__coloumn_2Something {
        grid-template-columns: 1fr 100px repeat(3, 1fr);
    }
}

@media screen and (max-width: 1200px)  {
    .grid__coloumn_2Something {
        grid-template-columns: 1fr 100px repeat(3, 180px);
    }
    .grid__coloumn_4 {
        grid-template-columns: 1fr;
    }
    .tableScrool {
        width: 600px !important;
        overflow: scroll;
    }
}


@media screen and (max-width: 1080px)  {
    .grid__coloumn_3 {
        grid-template-columns: 1fr 1fr;
    }


    .grid__coloumn_2Something {
        grid-template-columns: repeat(3, 1fr);
    }
    .grid__container{
        grid-gap: 2rem !important;
    }
}

@media screen and (max-width: 1050px)  {
    html {
        font-size: 56.5%;
    }

    .grid__container{
        grid-gap: 2rem !important;
    }
}

@media screen and (max-width: 880px)  {
    .grid__coloumn_2Something {
        grid-template-columns: repeat(2, 1fr);
    } 
    .grid__coloumn_6 {
        grid-template-columns: 1fr;
    }

    .flex__cart {
        display: block;
    }
}

@media screen and (max-width: 720px)  {
    .pagination_container {
        padding: 0rem 0 8rem 0;
    }
}
        
@media screen and (max-width: 650px)  {
    .text__center_sm {
        text-align: center;
    }
    .grid__container{
        grid-gap: 2rem !important;
    }


    .grid__coloumn_3 {
        grid-template-columns: 1fr;
    }

    .grid__coloumn_2Something {
        grid-template-columns: 1fr;
    } 
    
    .flex {
        justify-content: center;
    }

    .section-pd {
        padding: 2rem 1rem;
    }

    .input input {
        width: 100%;
        padding: 15px;
        border: 2px solid gray;
        border-radius: 8px;
        font-size: 16px;
        margin: 1px 0px;
    }

    .filter {
        margin-bottom: 2rem;
    }

    .diagnostics_table {
        overflow-x:auto;
    }

    .vaccine_card >div {
        padding: 4rem 2rem 2rem;
    }

    .vaccine_card .vaccine_left {
        padding: 2rem 2rem 4rem;
    }
 
}

@media screen and (max-width: 720px)  {
    .pagination_container {
        padding: 0rem 0 15rem 0;
    }
}
.DashboardElements_dashboard__items__acZFU {
    padding: 5rem 0;
    font-size: 1.6rem;
    background-color: #f2f2f2;
}

.DashboardElements_elements__container__1FNT6 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
}

.DashboardElements_element__1eV3j {
    flex-basis: 23.8%;
    background-color: #fff;
    border-radius: .8rem;
    padding: 4rem 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

.DashboardElements_element__img__27K6a {
    height: 6rem;
    width: 6rem;
}

@media screen and (max-width: 980px)  { 
    .DashboardElements_element__1eV3j {
        flex-basis: 100%;
    }
}
.DashboardGreetings_greetigs__1e6Or {
    background-color: #fff;
}

.DashboardGreetings_greet__container__2girA {
    padding: 4rem 0;
    background-color: #fff;
    font-size: 1.6rem;
}

.DashboardGreetings_light_dark__3ULhY {
    color: #938A8A;
}
.Admin_admin__shcjL {
    background-color: #f2f2f2;
    min-height: 82vh;
}
.DashboardFormTitle_title__container__2yeKi {
    margin: 3rem;
    padding: 0 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DashboardFormTitle_input__container__3vCF2 {
    display: flex;
    justify-content: center;
    align-items: center;

    grid-gap: 2rem;

    gap: 2rem;
}

.DashboardFormTitle_title__UYIHw {
    font-size: 1.8rem;
}

.DashboardFormTitle_input__1ikYn {
    position: relative;
}
.DashboardFormTitle_input__img__2pT3M {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-5%, -50%);
}
.DashboardFormTitle_input__field__14RCi {
    padding: 1.5rem 1rem;
    border: none;
    background-color: #f2f2f2;
    border-radius: .8rem;
}
.DashboardFormTitle_input__field__14RCi:focus {
    outline: none;
}
.DashboardSidebar_sidebar__TlpA- {
    flex-basis: 15%;
    background-color: royalblue;
    color:black;
}

.DashboardSidebar_sidebar__container__247Kv {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6rem;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem 5rem;    
}

.DashboardSidebar_sidebar__item__3Vxng {
    padding: 10px;
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    cursor: pointer;
}


.TelemedicineFormPage_telemedicine__3al7F {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.TelemedicineFormPage_telemedicine__form__mtJRK {
    flex-basis: 85%;
}

.TelemedicineFormPage_telemedicine__table__2mqZa {
    /* background-color: #F2F2F2; */
    padding: 3rem;
    margin: 3rem;
    /* border-radius: .8rem; */
    width: 80%;
}

table {
    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;

  width:100%;
  max-width: 100%;


}

.TeleMedicineTable_table__heading__2yQi0 th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
    
}

.TeleMedicineTable_table__heading__2yQi0 {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
}

.TeleMedicineTable_table__body__2hPW8 {
    background-color: #fff;
    border-radius: .8rem;
}

.TeleMedicineTable_table__body__2hPW8 tr td{
    padding: 1rem 2rem;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}


/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.TeleMedicineTable_done__3ERuE {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.TeleMedicineTable_pending__3SVIT {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}

.TeleMedicineTable_delUp__23owW {
    display: flex;
    justify-content: space-evenly;
}



table {
    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;

  width:100%;
  max-width: 100%;
}

.DashboardTable_table__heading__3LENd th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
    
}

.DashboardTable_table__heading__3LENd {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
}

.DashboardTable_table__body__2G_Km {
    background-color: #fff;
    border-radius: .8rem;
}



table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.DashboardTable_done__r7R_I {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.DashboardTable_pending__2pB7f {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}
.VaccineListPage_vaccine__container__3Uu8f {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.VaccineListPage_vaccine__form__3yCYJ {
    flex-basis: 85%;
}
.VaccineListPage_vaccine__listTable__2rt6b {
    padding: 3rem;
    margin: 3rem;
    border-radius: .8rem;
}
.DashboardFormlist_formlist__container__JTNZa {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DashboardFormlist_formlist__form__3tAXc {
    flex-basis: 85%;
}
.DashboardFormlist_formlist__listTable__2JUty {
    /* background-color: #F2F2F2; */
    padding: 3rem;
    margin: 3rem;
    /* border-radius: .8rem; */
}
.DashboardFormAdd_title__container__1sh9n {
    margin: 3rem;
    padding: 0 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DashboardFormAdd_input__container__14fzz {
    display: flex;
    justify-content: center;
    align-items: center;

    grid-gap: 2rem;

    gap: 2rem;
}

.DashboardFormAdd_title__26Ro1 {
    font-size: 1.8rem;
}

.DashboardFormAdd_input__3wD2r {
    position: relative;
}
.DashboardFormAdd_input__img__30TX3 {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-5%, -50%);
}
.DashboardFormAdd_input__field__2p3Mf {
    padding: 1.5rem 1rem;
    border: none;
    background-color: #f2f2f2;
    border-radius: .8rem;
}
.DashboardFormAdd_input__field__2p3Mf:focus {
    outline: none;
}

.DashboardFormAdd_button__container__3UyF1 {
    border: none;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    border-radius: .8rem;
    cursor: pointer;
}

.DashboardFormAdd_button__img__PjOUe {
}
.DashboardHospital_telemedicine__NaWsE {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DashboardHospital_telemedicine__form__vtYvh {
    flex-basis: 85%;
}

.DashboardHospital_telemedicine__table__2gUKd {
    padding: 3rem;
    margin: 3rem;
}
.DashboardForm_dashboardForm__1qzvW {
    background-color: #f2f2f2;
   padding: 3rem;
   border-radius: 8px;
   width: 70%;
   margin: 0 auto;
}

.DashboardForm_dashboardTitle__u6RYJ {
    font-size: 22px;
    line-height: 26px;
    color: #434343;
    padding: 2rem 0rem;
}

.DashboardForm_form__1VXey {
    text-align: center;
}

.DashboardForm_input__2w2tb {
   width: 100%;
}

.DashboardForm_input__2w2tb input{
   width: 100%;
   padding: 15px;
   border: 2px solid gray;
   border-radius: 8px;
   font-size: 22px;
   margin: 15px 0px;
}

textarea {
   width: 100%;
   padding: 25px;
   border: 2px solid gray;
   border-radius: 5px;
   font-size: 22px;
   margin: 15px 0px;
   color: gray;
   min-height: 20rem;
}

.DashboardForm_contact_form__36SC8 {
   margin-bottom: 1rem;
}

@media screen and (max-width: 650px)  {
   .DashboardForm_input__2w2tb input {
       width: 100%;
       padding: 15px;
       border: 2px solid gray;
       border-radius: 8px;
       font-size: 16px;
       margin: 1px 0px;
   }
}
.DashboardHospitalAdd_dashboard__form__1rbP7 {
    display: flex;
}

.DashboardHospitalAdd_dashbard__form_add__2GGyZ{
    flex-basis: 85%;
    margin: 3rem;
}
.DashboardSymptoms_telemedicine__14dqf {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DashboardSymptoms_telemedicine__form__-hlUq {
    flex-basis: 85%;
}

.DashboardSymptoms_telemedicine__table__1H50Y {
    padding: 3rem;
    margin: 3rem;
}
.DashboardSymptomsAdd_dashboard__form__2rgxW {
    display: flex;
}

.DashboardSymptomsAdd_dashbard__form_add__EbQT6{
    flex-basis: 85%;
    margin: 3rem;
}
.SymptomAddForm_dashboardForm__1L-6N {
    background-color: #f2f2f2;
   padding: 3rem;
   border-radius: 8px;
   width: 70%;
   margin: 0 auto;
}

.SymptomAddForm_dashboardTitle__2aLej {
    font-size: 22px;
    line-height: 26px;
    color: #434343;
    padding: 2rem 0rem;
}

.SymptomAddForm_form__9QK1U {
    text-align: center;
}

.SymptomAddForm_input__2EBuQ {
   width: 100%;
}

.SymptomAddForm_input__2EBuQ input{
   width: 100%;
   padding: 15px;
   border: 2px solid gray;
   border-radius: 8px;
   font-size: 22px;
   margin: 15px 0px;
}

textarea {
   width: 100%;
   padding: 25px;
   border: 2px solid gray;
   border-radius: 5px;
   font-size: 22px;
   margin: 15px 0px;
   color: gray;
   min-height: 20rem;
}

.SymptomAddForm_contact_form__v3yt_ {
   margin-bottom: 1rem;
}

.SymptomAddForm_contact_form__v3yt_ .SymptomAddForm_filter__2r0IO {
    width: 100%;
}

@media screen and (max-width: 650px)  {
   .SymptomAddForm_input__2EBuQ input {
       width: 100%;
       padding: 15px;
       border: 2px solid gray;
       border-radius: 8px;
       font-size: 16px;
       margin: 1px 0px;
   }
}
.DashboardTreatments_treatments__1OwWC {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DashboardTreatments_treatments__form__2kCAQ {
    flex-basis: 85%;
}

.DashboardTreatments_treatments__table__uh8K2 {
    background-color: #F2F2F2;
    padding: 3rem;
    margin: 3rem;
    border-radius: .8rem;
}
.DashboardTreatmentsAdd_dashboard__form__zRUaA {
    display: flex;
}

.DashboardTreatmentsAdd_dashbard__form_add__1XUJB{
    flex-basis: 85%;
    margin: 3rem;
}
.DashboardDiagonostics_diagonostics__142PR {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DashboardDiagonostics_diagonostics__form__1mnLz {
    flex-basis: 85%;
}

.DashboardDiagonostics_diagonostics__table__1Zo8z {
    padding: 3rem;
    margin: 3rem;
}

table {
    border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;

  width:100%;
  max-width: 100%;
  position: relative;
}

.DiagonosticsTable_table__heading__k2Gg7 th{
    color: #F2F2F2;
    border-right: 1px solid #cfcfcf;
    
}

.DiagonosticsTable_table__heading__k2Gg7 {
    border-radius: 8px;
    background-color: #262626;
    border: none;
    border-radius: .8rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
}

.DiagonosticsTable_table__body__1GBjL {
    background-color: #fff;
    border-radius: .8rem;
}



table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 18px;
    line-height: 22px;
    border: none;
}

/* tr:nth-child(:last-child){
    border-bottom: 3px solid transparent;
} */

td:not(:last-child) {
    border-right: 2px solid #CFCFCF;
}

td {
    border-bottom: 2px solid #CFCFCF;
}


.DiagonosticsTable_done__24lGJ {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    color: #00661D;
    background: #C7FFD6;
}

.DiagonosticsTable_pending__3NkqC {
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 45%;
    background: #FFE1E1;
    color: #EC0909;
}




.DiagonosticsTable_label__3FCKK {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.DiagonosticsTable_input__1bdC0 {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.DiagonosticsTable_btn__2Xrxk {
    width: 100%;
    border: none;
    padding: 2rem 3rem;
    border-radius: .4rem;
}
.DiagonsticsFormAdd_title__container__2QbBY {
    margin: 3rem;
    padding: 0 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DiagonsticsFormAdd_input__container__1axiS {
    display: flex;
    justify-content: center;
    align-items: center;

    grid-gap: 2rem;

    gap: 2rem;
}

.DiagonsticsFormAdd_title__3giMB {
    font-size: 1.8rem;
}

.DiagonsticsFormAdd_input__3FaLK {
    position: relative;
}
.DiagonsticsFormAdd_input__img__3CpOT {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-5%, -50%);
}
.DiagonsticsFormAdd_input__field__11h4s {
    padding: 1.5rem 1rem;
    border: none;
    background-color: #f2f2f2;
    border-radius: .8rem;
}
.DiagonsticsFormAdd_input__field__11h4s:focus {
    outline: none;
}

.DiagonsticsFormAdd_button__container__34c08 {
    border: none;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    border-radius: .8rem;
    cursor: pointer;
}

.DiagonsticsFormAdd_button__img__1ntFf {
}
.DashboardDiagonosticsAdd_dashboard__form__12fVK {
    display: flex;
}

.DashboardDiagonosticsAdd_dashbard__form_add__3zahg{
    flex-basis: 85%;
    margin: 3rem;
}
.DiagonosticsFormAdd_dashboardForm__XZLZ0 {
    background-color: #f2f2f2;
   padding: 3rem;
   border-radius: 8px;
   width: 70%;
   margin: 0 auto;
}

.DiagonosticsFormAdd_dashboardTitle__2sWcB {
    font-size: 22px;
    line-height: 26px;
    color: #434343;
    padding: 2rem 0rem;
}

.DiagonosticsFormAdd_form__1OrRU {
    text-align: center;
}

.DiagonosticsFormAdd_input__3HSky {
   width: 100%;
}

.DiagonosticsFormAdd_input__3HSky input{
   width: 100%;
   padding: 15px;
   border: 2px solid gray;
   border-radius: 8px;
   font-size: 22px;
   margin: 15px 0px;
}

textarea {
   width: 100%;
   padding: 25px;
   border: 2px solid gray;
   border-radius: 5px;
   font-size: 22px;
   margin: 15px 0px;
   color: gray;
   min-height: 20rem;
}

.DiagonosticsFormAdd_contact_form__3OnO3 {
   margin-bottom: 1rem;
}

@media screen and (max-width: 650px)  {
   .DiagonosticsFormAdd_input__3HSky input {
       width: 100%;
       padding: 15px;
       border: 2px solid gray;
       border-radius: 8px;
       font-size: 16px;
       margin: 1px 0px;
   }
}
.IndividualDiagonostics_diagonostics__card__1l6Av {
    /* border: 5px solid #ddd; */
    padding: 2rem;
    background-color: rgba(221, 221, 221, 0.37);
    border-radius: .8rem;
}

.IndividualDiagonostics_diagonostics__img_container__jV9vw {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}


.IndividualDiagonostics_diagonostics__img__1cgy6 {
    width: 50%;
}

.IndividualDiagonostics_diagonostics__name__3GPyU {
    font-size: 1.8rem;
    margin-top: 2rem;
}

.IndividualDiagonostics_diagonostics__price__3DQDe {
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.IndividualDiagonostics_price__19fq_ {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 2rem;    
}

.IndividualDiagonostics_diagonostics__btn__6uQZ4 {
    border: none;
    background: none;
    padding: 1rem 2rem;
    background-color: var(--primary-color);
    color: #fff;
    margin-top: 3rem;
    cursor: pointer;
}

.IndividualDiagonostics_diagonostics__btn__6uQZ4:hover {
    background-color: rgb(113, 113, 255);
}


.Diagonostics_diagonostics__P82xh {
    padding: 7rem 0;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 1200px)  {
    .Diagonostics_diagonostics__P82xh {
        padding: 2rem 0;
    }
} 
.Checkout_checkout__3Epxx {
    margin-bottom: 4rem;
}

.Checkout_checkout__details__1Xr4j {
    width: 40%;
    margin: 0 auto;
}

.Checkout_checkout__title__3E2VV {
    text-align: center;
    font-size: 3.2rem;
    color: #444;
    padding: 3rem 0;
}


@media screen and (max-width: 880px)  { 
    .Checkout_checkout__details__1Xr4j {
        width: 90%;
    }
}
.Billing_checkout__title__1i4sN {
    font-size: 2.2rem;
    margin: 2rem 0;
}


.Billing_input__container__2pSmK {
    display: block;
    margin: 2rem 0;
}

.Billing_label__28sDe {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.Billing_input__22S6p {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.Billing_checkbox__container__3ZqXv {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.Billing_para__1wpFJ {
    font-size: 1.8rem;
}
.Payment_payment__NzCu0 {
    border: .2rem solid #ddd;
    padding: 2rem;
}
.Payment_payment__title__3NeJ- {
    font-size: 2.2rem;
    margin: 2rem 0;
}

.Payment_payment__greet__2yXWm {
    font-size: 2.2rem;
    color: rgb(49, 49, 49);
    padding: 2rem 0;
}

.Payment_payment__value__3xAKT {
    font-size: 2.2rem;
    color: rgb(49, 49, 49);
    padding: 2rem 0;
}

.Payment_order__greet__Dct7D {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 2.2rem;
}
.Payment_order__value__vdaPm {
    font-size: 2.2rem;
}

.Payment_order__greet__Dct7D p, .Payment_order__value__vdaPm p {
    padding: 2rem 0;
    border-bottom: 1px solid #ddd;
}
.VaccineDetails_body__parts__uEW-7 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-gap: 1rem;
    gap: 1rem;
}


.VaccineDetails_adult__body__306Ij, .VaccineDetails_women__body__3ZwoP, .VaccineDetails_baby__body__3DbeQ {
    flex: 1 1;
    cursor: pointer;
    fill: #fff;
    stroke: #000;
}


.VaccineDetails_adult__body__306Ij:hover, .VaccineDetails_women__body__3ZwoP:hover, .VaccineDetails_baby__body__3DbeQ:hover {
    fill: #008080;
    stroke: #000;
}

.VaccineDetails_vaccine__title__2grBX {
    text-align: center;
    margin-bottom: 3rem;
}

.VaccineDetails_vaccine__list__1nUAI {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VaccineDetails_vaccineName__1-Vi6 {
    background: #00acac;
    width: 90%;
    padding: 2rem 0;
    text-align: center;
    margin: 1rem;
    color: #fff;
    border-radius: .8rem;
}


.VaccineDetails_bglight__d2KDr {
    background-color: #008080 !important;
}

.VaccineDetails_fillight__37HuV {
    fill: #00acac ;
}

.VaccineDetails_hover__3t3qm:.hover {
    fill: red !important;
}

@media screen and (max-width: 650px)  {
    .VaccineDetails_menRegion__3Ad_y {
        height: 190px;
        width: 90px;
        margin-top: 50px;
    }
    
    .VaccineDetails_womenRegion__3AZTV {
        height: 180px;
        width: 80px;
    }

    .VaccineDetails_babyRegion__2DQFE {
        height: 150px;
        width: 80px;
    }
}

@media screen and (max-width: 350px)  {
    .VaccineDetails_menRegion__3Ad_y {
        height: 170px;
        width: 75px;
        margin-top: 50px;
    }
    
    .VaccineDetails_womenRegion__3AZTV {
        height: 160px;
        width: 65px;
    }

    .VaccineDetails_babyRegion__2DQFE {
        height: 130px;
        width: 55px;
    }
}
.Vaccine_vaccine__page__3hDxh {
    min-height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Vaccine_btn__container__26yxS {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Vaccine_btn__5uYZ6 {
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    border-radius: .8rem;
    margin-top: 2rem;
    cursor: pointer;
}
.TeleMedicineDetails_details__3H_-v {
    width: 100%;
    color: #000;
    background-color: var(--white-color);
    border-radius: 1rem;
    padding: 8rem 4rem;
    margin: 3rem auto;
}

.TeleMedicineDetails_details__container__RMgAi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.TeleMedicineDetails_body__segment_container__17ZlH {
    flex-basis: 33.33%;
}



.TeleMedicineDetails_body__segment__3pz2d {
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-right: 5px solid #008080;
}

.TeleMedicineDetails_details__subtitle__3r7zW {
    text-align: center;
    margin-bottom: 3rem;
}

.TeleMedicineDetails_organ__selection__1wkxe {
    flex-basis: 33.33%;
    text-align: center;
}


.TeleMedicineDetails_organ__lists__3rig8 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}
.TeleMedicineDetails_organ__list__EAja- {
    flex-basis: 33%;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

/* .organ__list img {
    width: 12rem;
    height: 10rem;
} */

.TeleMedicineDetails_organImg__SIKYt {
    height: 8rem;
    margin: 1rem;
    padding: 10px;
}

.TeleMedicineDetails_organImg__SIKYt:hover {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}

.TeleMedicineDetails_bg__1Tyo4 {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}

.TeleMedicineDetails_symptom__2QmAZ {
    flex-basis: 33.33%;
}
.TeleMedicineDetails_symptom__list__xh_Rm {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.TeleMedicineDetails_symptom__name__1FSif {
    background: #00a5a5;
    width: 90%;
    padding: 2rem 1rem;
    text-align: center;
    margin: 1rem;
    color: #fff;
    border-radius: .8rem;
}

.TeleMedicineDetails_bglight__1zIwk {
    background-color: #008080 !important;
}

.TeleMedicineDetails_body__segment__3pz2d, .TeleMedicineDetails_organ__lists__3rig8, .TeleMedicineDetails_symptom__list__xh_Rm {
    margin: 2rem 0rem !important;
    padding: 1rem;
    /* border-radius: .8rem; */
    height: 300px;
}

.TeleMedicineDetails_body__segment_baby__GTTfL {
    margin-right: 2rem;
}

.TeleMedicineDetails_body__part__1GevE {
    cursor: pointer;
}

.TeleMedicineDetails_body__part__1GevE:hover {
    fill: #008080;
}



.TeleMedicineDetails_btn__container__1eo44 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TeleMedicineDetails_btn__1QRDT {
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    border-radius: .8rem;
    margin-top: 2rem;
    cursor: pointer;
}



@media screen and (max-width: 1300px)  {
    .TeleMedicineDetails_body__segment_baby__GTTfL {
        margin-right: 1rem;
    }
}
@media screen and (max-width: 1200px)  {
    .TeleMedicineDetails_body__segment_baby__GTTfL {
        margin-right: .5rem;
    }
}

@media screen and (max-width: 1080px)  {
    .TeleMedicineDetails_body__segment__3pz2d {
        justify-content: space-around;
    }
}

@media screen and (max-width: 980px)  {
    .TeleMedicineDetails_details__3H_-v {
        width: 90%;
        margin: 0 auto;
    }

    .TeleMedicineDetails_details__container__RMgAi { 
        display: block;
    }
    .TeleMedicineDetails_body__segment_container__17ZlH,.TeleMedicineDetails_organ__lists__3rig8, .TeleMedicineDetails_organ__selection__1wkxe, .TeleMedicineDetails_symptom__list__xh_Rm {
        flex-basis: 100%;
        min-height: auto;
    }

    .TeleMedicineDetails_body__segment__3pz2d, .TeleMedicineDetails_organ__lists__3rig8, .TeleMedicineDetails_symptom_name__gneSE {
        border: 0px;
    }
}

@media screen and (max-width: 650px)  {
    .TeleMedicineDetails_details__3H_-v {
         display: flex;
         flex-direction: column;
        justify-content: center;
        align-items: center
    }
    .TeleMedicineDetails_menRegion__2SudV {
        height: 190px;
        width: 90px;
        margin-top: 50px;
    }
    
    .TeleMedicineDetails_womenRegion__1WCul {
        height: 180px;
        width: 80px;
    }

    .TeleMedicineDetails_babyRegion__3GTtS {
        height: 150px;
        width: 80px;
    }
}

@media screen and (max-width: 350px)  {
    .TeleMedicineDetails_menRegion__2SudV {
        height: 170px;
        width: 75px;
        margin-top: 50px;
    }
    
    .TeleMedicineDetails_womenRegion__1WCul {
        height: 160px;
        width: 65px;
    }

    .TeleMedicineDetails_babyRegion__3GTtS {
        height: 130px;
        width: 55px;
    }

    .TeleMedicineDetails_body__segment__3pz2d { 
        height: auto;
    }
}





.TeleMedicine_telemedicine__page__3RZ0f {
    min-height: 82vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PartnerForm_checkout__title__-TJA8 {
    font-size: 2.2rem;
    margin: 2rem 0;
}


.PartnerForm_input__container__129o1 {
    display: block;
    margin: 2rem 0;
}

.PartnerForm_label__1EgYI {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.PartnerForm_input__3HDsf {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.PartnerForm_checkbox__container__2S8ot {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.PartnerForm_para__3ra0D {
    font-size: 1.8rem;
}
.BecameAPartner_partner__page__2D_VF {
    width: 40%;
    margin: 100px auto;
}

@media screen and (max-width: 680px)  { 
    .BecameAPartner_partner__page__2D_VF  {
        width: 90%;
    }
}
.TreatmentsDetails_details__3gdNR {
    width: 100%;
    color: #000;
    background-color: var(--white-color);
    border-radius: 1rem;
    padding: 8rem 4rem;
    margin: 3rem;
}

.TreatmentsDetails_details__container__hZlsK {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.TreatmentsDetails_body__segment_container__2on5h {
    flex-basis: 33.33%;
}



.TreatmentsDetails_body__segment__1quyJ {
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-right: 5px solid #008080;
    /* margin: 0 -10px !important; */
}

.TreatmentsDetails_details__subtitle__UgmEe {
    text-align: center;
    margin-bottom: 3rem;
}

.TreatmentsDetails_organ__selection__zoqJV {
    flex-basis: 33.33%;
    text-align: center;
}


.TreatmentsDetails_organ__lists__3dEds {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}
.TreatmentsDetails_organ__list__R6f8r {
    flex-basis: 33%;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

/* .organ__list img {
    width: 12rem;
    height: 10rem;
} */

.TreatmentsDetails_organImg__2DOju {
    height: 8rem;
    margin: 1rem;
    padding: 10px;
}

.TreatmentsDetails_organImg__2DOju:hover {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}



.TreatmentsDetails_bg__1P8iD {
    background-color: #008080;
    border-radius: 50%;
    padding: 10px;
}

.TreatmentsDetails_bglight__1dZq1 {
    background-color: #008080 !important;
}

.TreatmentsDetails_symptom__23s8b {
    flex-basis: 33.33%;
}
.TreatmentsDetails_symptom__list__2uWaQ {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.TreatmentsDetails_symptom__name__Xi0vh {
    background: #00b6b6;
    width: 90%;
    padding: 2rem 0;
    text-align: center;
    margin: 1rem;
    color: #fff;
    border-radius: .8rem;
}

.TreatmentsDetails_body__segment__1quyJ, .TreatmentsDetails_organ__lists__3dEds, .TreatmentsDetails_symptom__list__2uWaQ {
    margin: 2rem 0rem !important;
    padding: 1rem;
    /* border-radius: .8rem; */
    height: 300px;
}

.TreatmentsDetails_body__part__3mK6G {
    cursor: pointer;
}

.TreatmentsDetails_babyRegion__7UzUF {
    margin-right: 2rem;
}

.TreatmentsDetails_body__part__3mK6G:hover {
    fill: #008080;
}



.TreatmentsDetails_btn__container__1igrW {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TreatmentsDetails_btn__321Pn {
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    border-radius: .8rem;
    margin-top: 2rem;
    cursor: pointer;
}

@media screen and (max-width: 1080px)  {
    .TreatmentsDetails_body__segment__1quyJ {
        justify-content: space-around;
    }
}
@media screen and (max-width: 980px)  {
    .TreatmentsDetails_details__3gdNR {
        width: 90%;
    }

    .TreatmentsDetails_details__container__hZlsK { 
        display: block;
    }
    .TreatmentsDetails_body__segment_container__2on5h,.TreatmentsDetails_organ__lists__3dEds, .TreatmentsDetails_organ__selection__zoqJV, .TreatmentsDetails_symptom__list__2uWaQ {
        flex-basis: 100%;
        min-height: auto;
    }

    .TreatmentsDetails_body__segment__1quyJ, .TreatmentsDetails_organ__lists__3dEds, .TreatmentsDetails_symptom_name__2YYAb {
        border: 0px;
    }
}

@media screen and (max-width: 650px)  {
    .TreatmentsDetails_details__3gdNR {
        padding: 4rem 2rem;
        margin: 0 3rem;
    }
    .TreatmentsDetails_menRegion__3L77D {
        height: 190px;
        width: 90px;
        margin-top: 50px;
    }
    
    .TreatmentsDetails_womenRegion__3HB_p {
        height: 180px;
        width: 80px;
    }

    .TreatmentsDetails_babyRegion__7UzUF {
        height: 150px;
        width: 80px;
    }
}

@media screen and (max-width: 350px)  {
    .TreatmentsDetails_menRegion__3L77D {
        height: 170px;
        width: 75px;
        margin-top: 50px;
    }
    
    .TreatmentsDetails_womenRegion__3HB_p {
        height: 160px;
        width: 65px;
    }

    .TreatmentsDetails_babyRegion__7UzUF {
        height: 130px;
        width: 55px;
    }

    .TreatmentsDetails_body__segment__1quyJ { 
        height: auto;
    }
}





.Treatments_treatment__page__3zOsd {
    min-height: 75vh;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

@media screen and (max-width: 950px)  {
    .Treatments_treatment__page__3zOsd {
        flex-direction: column;
    }
}
.DashboardBecamePartner_diagonostics__2VUs5 {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DashboardBecamePartner_diagonostics__form__1VB7x {
    flex-basis: 85%;
}

.DashboardBecamePartner_diagonostics__table__3lbA4 {
    padding: 3rem;
    margin: 3rem;
}
.DiagonsticsSelected_card__3be3I {
    background-color: #fff;
    border-radius: 1.2rem;
    padding: 5rem;
    font-size: 1.8rem;
}

.DiagonsticsSelected_btn__container__24a7N {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

@media screen and (max-width: 650px)  {
    .DiagonsticsSelected_scard__-_og9{
     width: 500px
    }    
}
.SelectedItems_row__2Y3HV {
    background-color: #fff;
    padding: 1rem;
    border-radius: .8rem;
    margin: 1rem 0;
}
.SignUp_signup_form__27cu8 {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
    font-size: 2.2rem;
  }
  .SignUp_signup_form__27cu8 label {
    display: block;
    margin: 30px auto;
  }
  .SignUp_signup_form__27cu8 span {
    display: block;
    margin-bottom: 6px;
  }
  .SignUp_signup_form__27cu8 input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }
.Login_login_form__12x9v {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
    font-size: 2.2rem;
  }
  .Login_login_form__12x9v label {
    display: block;
    margin: 30px auto;
  }
  .Login_login_form__12x9v span {
    display: block;
    margin-bottom: 6px;
  }
  .Login_login_form__12x9v input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }
.DiagonsticsCheckout_diagonstics__Checkout__2N_tp{
    width: 40%;
    margin: 0 auto;
}

.DiagonsticsCheckout_checkout__title__2YCi4 {
    font-size: 2.2rem;
    margin: 2rem 0;
}


.DiagonsticsCheckout_input__container__1PLQb {
    display: block;
    margin: 2rem 0;
}

.DiagonsticsCheckout_label__2DhxQ {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.DiagonsticsCheckout_input__3Vdku {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.DiagonsticsCheckout_checkbox__container__3V--n {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.DiagonsticsCheckout_para__1Bp08 {
    font-size: 1.8rem;
}

.DiagonsticsCheckout_selected__item__1kbzf {
    font-size: 1.8rem;
}

.DiagonsticsCheckout_selected__name__2TvZJ {
    margin-left: 2rem;
    color: blue;
    background-color: rgb(210, 210, 255);
    padding: 2rem 4rem;
    margin: .5rem;
    border-radius: .8rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DiagonsticsCheckout_radio__container__t0NNK {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    margin: 1rem;
    font-size: 1.8rem;
}

.DiagonsticsCheckout_radio__title__1paIf {
    font-size: 2rem;
}

@media screen and (max-width: 780px)  { 
    .DiagonsticsCheckout_diagonstics__Checkout__2N_tp  {
        width: 90%;
    }

    .DiagonsticsCheckout_selected__name__2TvZJ { 
        padding: 2rem 1rem;
    }
}
.Products_productTitle__QSzn6 {
    font-size: 2.2rem;
    margin-bottom: 4rem;
    margin-top: 2rem;
}

.Products_card__container__yyO4r {
    margin: 2rem;
}

.Products_product__3Bzum {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    color: #fff;
    padding: 1rem;
    grid-gap: 2rem;
    gap: 2rem;
}

.Products_product__3Bzum img {
    width: 50px;
    height: 50px
}

.Products_product__3Bzum p {
    font-size: 2rem;
}
.DiagonosticsOrder_telemedicine__-M50O {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.DiagonosticsOrder_telemedicine__form__gnf5W {
    flex-basis: 85%;
}

.DiagonosticsOrder_telemedicine__table__1NL6X {
    padding: 3rem;
    margin: 3rem;
}
.VaccineOrder_telemedicine__3mYlb {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    min-height: 82vh;
}

.VaccineOrder_telemedicine__form__9W-Za {
    flex-basis: 85%;
}

.VaccineOrder_telemedicine__table__RI-0a {
    padding: 3rem;
    margin: 3rem;
}
.VaccineSelected_card__1zS4m {
    background-color: #fff;
    border-radius: 1.2rem;
    padding: 5rem;
    font-size: 1.8rem;
}

.VaccineSelected_btn__container__3colP {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

@media screen and (max-width: 650px)  {
    .VaccineSelected_scard__j-brT{
     width: 500px
    }    
}
.SelectedVaccine_row__1zcA2 {
    background-color: #fff;
    padding: 1rem;
    border-radius: .8rem;
    margin: 1rem 0;
}
.VaccineCheckout_diagonstics__Checkout__s_XFV{
    width: 40%;
    margin: 3rem auto;
}

.VaccineCheckout_checkout__title__2Qpck {
    font-size: 2.2rem;
    margin: 2rem 0;
}


.VaccineCheckout_input__container__3fuGl {
    display: block;
    margin: 2rem 0;
}

.VaccineCheckout_label__B4XQb {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.VaccineCheckout_input__31Ud8 {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.VaccineCheckout_checkbox__container__12mD3 {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.VaccineCheckout_para__1abmR {
    font-size: 1.8rem;
}

.VaccineCheckout_selected__item__2iZWc {
    font-size: 1.8rem;
}

.VaccineCheckout_selected__name__egik3 {
    margin-left: 2rem;
    color: blue;
    background-color: rgb(210, 210, 255);
    padding: 2rem 4rem;
    margin: .5rem;
    border-radius: .8rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.VaccineCheckout_radio__container__xO7Nu {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    margin: 1rem;
    font-size: 1.8rem;
}

.VaccineCheckout_radio__title__bYy8P {
    font-size: 2rem;
}

@media screen and (max-width: 780px)  { 
    .VaccineCheckout_diagonstics__Checkout__s_XFV  {
        width: 90%;
    }

    .VaccineCheckout_selected__name__egik3 {
        padding: 2rem 1rem;
    }
}
.Footer_footer__2hSDG {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 8rem 0;
}

.Footer_footer_grid__2ThL1 {
    grid-gap: 4rem;
    gap: 4rem
}

.Footer_footer__logo__EpIH3 {
    margin-bottom: 2rem;
    min-width: 200px;
}

.Footer_footer__location_details__3dmo_ {
    color: #fff;
    font-weight: 300;
    font-style: normal;
    font-size: 1.8rem;
}

.Footer_footer_lists__DLayL {
    list-style: none;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem
}
.Footer_footer_list__2C7Pu {
    font-size: 1.8rem;
    font-weight: 300;
}
.Footer_footer_list__2C7Pu:first-child {
    font-size: 1.8rem;
    font-weight: 700;
}

.Footer_partner__2A5OC {
    border: 2px solid #fff;
    padding: 1rem;
    /* overflow: hidden; */
    display: inline-block;
}

.Footer_footer__greet__eY1KB {
    font-size: 1.8rem;
    width: 100%;
    margin-top: 2rem;
}

.Footer_Details__3f1R2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.Footer_footer__social__1ixdQ {
    margin: 0 auto ;
}

@media screen and (max-width: 650px)  {
    .Footer_footer__greet__eY1KB {
        padding-top: 2rem;
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
}
.MainNavigation_main__navigation__3PSPA {
    /* box-shadow: -2px -2px 40px rgba(0, 0, 0, 0.15); */
    min-height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}

.MainNavigation_main__lists__1jZOY {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2.5rem 0;
    list-style: none;
    grid-gap: 4rem;
    gap: 4rem;
    width: 80%;
    margin: 0 auto;
    font-size: 1.8rem;
    cursor: pointer;
}

.MainNavigation_menuOpen__1YIz- {
    position: fixed;
    top: 4%;
    right: 4%;
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    padding: .7rem;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    display: none;
    z-index: 10;
}

.MainNavigation_navlink__12qW7 {
    text-decoration: none;
    color: #000;
}

@media screen and (max-width: 1380px)  {
    .MainNavigation_main__lists__1jZOY {
        font-size: 1.5rem;
        grid-gap: 2rem;
        gap: 2rem
    }
}


@media screen and (max-width: 980px)  {
    .MainNavigation_main__navigation__3PSPA {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
    }

    .MainNavigation_none__2kIvY {
        display: none; 
    }

    .MainNavigation_main__lists__1jZOY {
        flex-direction: column;
        text-align: center;
    }

    .MainNavigation_menuOpen__1YIz- {
        display: block;
    }
}

.TopNavigation_top__navigation_container__18_j7 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
    min-height: 8vh;

    display: flex;
    align-items: center;
}

.TopNavigation_top__navigation__2ML6R {
    width: 80%;
    margin: 0 auto;
    padding: 1rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem
}

.TopNavigation_top__navigation_logo__3oInu {
    width: 300px;
    min-width: 20%;
    height: 100%;
}


.TopNavigation_form__button__3p3MH {
    background-color: rgb(230, 0, 0);
    color: #fff;
    border: none;
    padding: 1rem 3.5rem ;
    font-size: 1.8rem;
    border-radius: .8rem;
    text-transform: uppercase;
    cursor: pointer;
}

.TopNavigation_list__container__2Djju {
    display: flex;
    justify-content: flex-end;
}

.TopNavigation_list__j5Aio {
    margin: 2rem;
}

.TopNavigation_list__container__2Djju .TopNavigation_list__j5Aio a {
    text-decoration: none;
    font-size: 2.2rem;
    color: var(--dark-grey);
}



@media screen and (max-width: 1200px)  {
    .TopNavigation_form__button__3p3MH { 
        right: 15%;
    }
}


@media screen and (max-width: 540px)  {
    .TopNavigation_top__navigation__2ML6R {
        flex-direction: column;
    }
}
