.diagonstics__Checkout{
    width: 40%;
    margin: 0 auto;
}

.checkout__title {
    font-size: 2.2rem;
    margin: 2rem 0;
}


.input__container {
    display: block;
    margin: 2rem 0;
}

.label {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.input {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.checkbox__container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.para {
    font-size: 1.8rem;
}

.selected__item {
    font-size: 1.8rem;
}

.selected__name {
    margin-left: 2rem;
    color: blue;
    background-color: rgb(210, 210, 255);
    padding: 2rem 4rem;
    margin: .5rem;
    border-radius: .8rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radio__container {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 1rem;
    font-size: 1.8rem;
}

.radio__title {
    font-size: 2rem;
}

@media screen and (max-width: 780px)  { 
    .diagonstics__Checkout  {
        width: 90%;
    }

    .selected__name { 
        padding: 2rem 1rem;
    }
}