.diagonostics {
    display: flex;
    gap: 2rem;
    min-height: 82vh;
}

.diagonostics__form {
    flex-basis: 85%;
}

.diagonostics__table {
    padding: 3rem;
    margin: 3rem;
}