.greetigs {
    background-color: #fff;
}

.greet__container {
    padding: 4rem 0;
    background-color: #fff;
    font-size: 1.6rem;
}

.light_dark {
    color: #938A8A;
}