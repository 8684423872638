.checkout {
    margin-bottom: 4rem;
}

.checkout__details {
    width: 40%;
    margin: 0 auto;
}

.checkout__title {
    text-align: center;
    font-size: 3.2rem;
    color: #444;
    padding: 3rem 0;
}


@media screen and (max-width: 880px)  { 
    .checkout__details {
        width: 90%;
    }
}