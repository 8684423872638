.checkout__title {
    font-size: 2.2rem;
    margin: 2rem 0;
}


.input__container {
    display: block;
    margin: 2rem 0;
}

.label {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
}

.input {
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    font-size: 1.8rem;
    border: 1px solid #ddd;
}

.checkbox__container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.para {
    font-size: 1.8rem;
}