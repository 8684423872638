.payment {
    border: .2rem solid #ddd;
    padding: 2rem;
}
.payment__title {
    font-size: 2.2rem;
    margin: 2rem 0;
}

.payment__greet {
    font-size: 2.2rem;
    color: rgb(49, 49, 49);
    padding: 2rem 0;
}

.payment__value {
    font-size: 2.2rem;
    color: rgb(49, 49, 49);
    padding: 2rem 0;
}

.order__greet {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 2.2rem;
}
.order__value {
    font-size: 2.2rem;
}

.order__greet p, .order__value p {
    padding: 2rem 0;
    border-bottom: 1px solid #ddd;
}