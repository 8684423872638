.vaccine__page {
    min-height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 3rem;
    border-radius: .8rem;
    margin-top: 2rem;
    cursor: pointer;
}