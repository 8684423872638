.form {
     max-width: 80%;
    margin: 0 auto;
    text-align: center;
}

.input {
    width: 100%;
}

.input input{
    width: 100%;
    padding: 15px;
    border: 2px solid gray;
    border-radius: 8px;
    font-size: 22px;
    margin: 15px 0px;
}

textarea {
    width: 100%;
    padding: 25px;
    border: 2px solid gray;
    border-radius: 5px;
    font-size: 22px;
    margin: 15px 0px;
    color: gray;
    min-height: 20rem;
}

.contact_form {
    margin-bottom: 1rem;
}

@media screen and (max-width: 650px)  {
    .input input {
        width: 100%;
        padding: 15px;
        border: 2px solid gray;
        border-radius: 8px;
        font-size: 16px;
        margin: 1px 0px;
    }
    .form {
        max-width: 100%;
   }
   
}