.main__navigation {
    /* box-shadow: -2px -2px 40px rgba(0, 0, 0, 0.15); */
    min-height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}

.main__lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2.5rem 0;
    list-style: none;
    gap: 4rem;
    width: 80%;
    margin: 0 auto;
    font-size: 1.8rem;
    cursor: pointer;
}

.menuOpen {
    position: fixed;
    top: 4%;
    right: 4%;
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    padding: .7rem;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    display: none;
    z-index: 10;
}

.navlink {
    text-decoration: none;
    color: #000;
}

@media screen and (max-width: 1380px)  {
    .main__lists {
        font-size: 1.5rem;
        gap: 2rem
    }
}


@media screen and (max-width: 980px)  {
    .main__navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
    }

    .none {
        display: none; 
    }

    .main__lists {
        flex-direction: column;
        text-align: center;
    }

    .menuOpen {
        display: block;
    }
}