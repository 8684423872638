.vaccine__container {
    display: flex;
    gap: 2rem;
    min-height: 82vh;
}

.vaccine__form {
    flex-basis: 85%;
}
.vaccine__listTable {
    padding: 3rem;
    margin: 3rem;
    border-radius: .8rem;
}