.title__container {
    margin: 3rem;
    padding: 0 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input__container {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 2rem;
}

.title {
    font-size: 1.8rem;
}

.input {
    position: relative;
}
.input__img {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-5%, -50%);
}
.input__field {
    padding: 1.5rem 1rem;
    border: none;
    background-color: #f2f2f2;
    border-radius: .8rem;
}
.input__field:focus {
    outline: none;
}

.button__container {
    border: none;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-radius: .8rem;
    cursor: pointer;
}

.button__img {
}