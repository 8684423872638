.contact {
    max-width: 60%;
    margin: 0 auto;
}

.grid {
  display: grid;
  gap: 4rem;
  grid-template-columns: minmax(35rem, 1fr) minmax(35rem, 1fr);
  align-items: center;
}


@media screen and (max-width: 980px)  {
  .grid {
    grid-template-columns: 1fr;
  }   
}

@media screen and (max-width: 650px)  {
  .contact {
      max-width: 90%;
  }
   
}